import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

type DocumentPictureInPictureContextType = {
  open: (width: number, height: number) => void
  close: () => void
  isOpen: boolean
  pipWindow: Window | null
  isSupported: boolean
}

const DocumentPictureInPictureContext =
  React.createContext<DocumentPictureInPictureContextType | null>(null)

export const useDocumentPictureInPictureContext = () => {
  const context = React.useContext(DocumentPictureInPictureContext)

  if (context === null) {
    throw new TypeError(
      `useDocumentPictureInPictureContext must be rendered within a DocumentPictureInPictureContextProvider`
    )
  }

  return context
}

export const DocumentPictureInPictureProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [pip, setPip] = useState<Window | null>(null)
  const observerRef = useRef<MutationObserver | null>(null)

  const copyStyles = useCallback((pipWindow: Window) => {
    if (!pipWindow) return

    // Clear existing styles to avoid duplication
    pipWindow.document.head.innerHTML = ""

    // Copy styles from the current page into the PIP window
    ;[...document.styleSheets].forEach((stylesheet) => {
      try {
        const styleText = [...stylesheet.cssRules]
          .map((rule) => rule.cssText)
          .join("")
        const styleElement = document.createElement("style")
        styleElement.textContent = styleText
        pipWindow.document.head.appendChild(styleElement)
      } catch (e) {
        // Can only copy same-origin stylesheets so this breaks on google fonts etc
        if (e.message.match(/Cannot access rules/)) {
          return
        }
      }
    })
  }, [])

  const open = useCallback(
    async (width: number, height: number) => {
      // Don't allow multiple windows
      if (pip) return

      if (!("documentPictureInPicture" in window)) {
        console.error("Document picture in picture not supported")
      }

      const pipWindow = await window.documentPictureInPicture.requestWindow({
        width,
        height,
        disallowReturnToOpener: true,
      })

      pipWindow.addEventListener("pagehide", close)

      // Immediately copy styles after creating the PiP window
      copyStyles(pipWindow)

      setPip(pipWindow)
    },
    [pip]
  )

  const close = useCallback(() => {
    // onClose()
    window.documentPictureInPicture?.window?.close()
    observerRef.current?.disconnect()
    setPip(null)
  }, [])

  useEffect(() => {
    if (!pip) return

    const observer = new MutationObserver(() => copyStyles(pip))
    observerRef.current = observer

    observer.observe(document.head, {
      childList: true,
      subtree: true,
    })

    // Clean up observer on component unmount or when pip changes
    return () => {
      observer.disconnect()
    }
  }, [pip])

  return (
    <DocumentPictureInPictureContext.Provider
      value={{
        open,
        close,
        isOpen: !!pip,
        pipWindow: pip,
        isSupported: "documentPictureInPicture" in window,
      }}
    >
      {children}
    </DocumentPictureInPictureContext.Provider>
  )
}
