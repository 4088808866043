import {
  Box,
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import {
  ColumnFiltersState,
  Header,
  Row,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ArrowDownSolidIcon } from "Shared/icons/untitled-ui/ArrowDownSolidIcon"
import { ArrowUpSolidIcon } from "Shared/icons/untitled-ui/ArrowUpSolidIcon"
import { Response } from "Types"
import { longWithTime } from "Utilities/date-formats"
import { formatDuration } from "date-fns"
import { upperFirst } from "lodash"
import React, { useState } from "react"
import { useFilteredResponses } from "../hooks/use-filtered-responses"
import { useIndividualResponseFilter } from "../hooks/use-individual-response-filter"

const columnHelper = createColumnHelper<Response>()

const COLUMNS = [
  columnHelper.accessor("user_id", {
    header: "Panelist ID",
    cell: (info) => <Text>{info.getValue()}</Text>,
    minSize: 50,
    size: 50,
    maxSize: 50,
  }),
  columnHelper.accessor("order_id", {
    header: "Source",
    cell: (info) => {
      const value = info.getValue()
      if (!value) return <Text>Self-recruited</Text>
      return <Text>Order {value}</Text>
    },
    minSize: 70,
    size: 70,
    maxSize: 70,
  }),
  columnHelper.accessor("device_type", {
    header: "Device type",
    cell: (info) => {
      return <Text>{upperFirst(info.getValue() ?? "unknown")}</Text>
    },
    minSize: 60,
    size: 60,
    maxSize: 60,
  }),
  columnHelper.accessor("platform", {
    header: "Platform",
    cell: (info) => {
      return <Text>{upperFirst(info.getValue() ?? "unknown")}</Text>
    },
    minSize: 60,
    size: 60,
    maxSize: 60,
  }),
  columnHelper.accessor("submitted_at", {
    header: "Submitted at",
    cell: (info) => {
      const value = info.getValue()
      if (!value) return <Text>-</Text>
      const date = new Date(value)
      return <Text>{longWithTime(date)}</Text>
    },
  }),
  columnHelper.accessor("duration_ms", {
    header: "Time taken",
    cell: (info) => {
      const value = info.getValue() ?? 0
      const seconds = Math.round(value / 1000)
      return <Text>{formatDuration({ seconds })}</Text>
    },
    minSize: 60,
    size: 60,
    maxSize: 60,
  }),
]

export const ExternalStudyParticipantTable: React.FC = () => {
  const filteredResponses = useFilteredResponses() as Response[] // Something weird with Readonly<> here
  const [, setResponseId] = useIndividualResponseFilter()

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "submitted_at",
      desc: false,
    },
  ])
  const [globalFilter] = useState("")
  const [columnFilters] = React.useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<
    Record<string, boolean>
  >({})

  const table = useReactTable({
    data: filteredResponses,
    columns: COLUMNS,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableSortingRemoval: false,
    state: {
      sorting,
      globalFilter,
      columnFilters,
      columnVisibility,
    },
  })

  if (filteredResponses.length === 0) {
    return (
      <Center minH="200px">
        <Text>No responses yet</Text>
      </Center>
    )
  }

  return (
    <Flex w="full" gap={4} mx="auto" alignItems="flex-start">
      <Flex
        direction="column"
        borderRadius="md"
        bg="white"
        borderWidth="1px"
        borderColor="gray.300"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flex={1}
      >
        <Box width="full" overflowX="auto">
          <TableContainer isolation="isolate">
            <Table layout="fixed">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index, allHeaders) => (
                      <TableHeader
                        key={header.id}
                        header={header}
                        index={index}
                        allHeaders={allHeaders}
                      />
                    ))}
                  </Tr>
                ))}
              </Thead>

              <Tbody>
                {table.getRowModel().rows.map((row, rowIndex, allRows) => (
                  <TableRow
                    key={row.id}
                    row={row}
                    rowIndex={rowIndex}
                    allRows={allRows}
                    openIndividualResponseDrawer={setResponseId}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Flex>
    </Flex>
  )
}

type TableHeaderProps = {
  header: Header<Response, unknown>
  index: number
  allHeaders: Header<Response, unknown>[]
}

const TableHeader: React.FC<TableHeaderProps> = ({
  header,
  index,
  allHeaders,
}) => {
  return (
    <Th
      key={header.id}
      onClick={header.column.getToggleSortingHandler()}
      userSelect="none"
      width={`${header.getSize()}px`}
      px="3"
      py="0"
      h="44px"
      borderColor="gray.200"
      borderWidth={1}
      borderLeftWidth={1}
      borderRightWidth={index === allHeaders.length - 1 ? 0 : 1}
      borderTopWidth={0}
      textTransform="none"
      textColor="gray.500"
      fontSize="sm"
      fontWeight="medium"
      letterSpacing="normal"
      _hover={header.column.getCanSort() ? { cursor: "pointer" } : {}}
    >
      <Flex justifyContent="space-between" alignItems="center">
        {header.id === "select" ? (
          // Don't try to wrap the checkbox field in a <p> or the DOM will be sad
          flexRender(header.column.columnDef.header, header.getContext())
        ) : (
          <Text isTruncated>
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </Text>
        )}
        <div>
          {header.column.getIsSorted() ? (
            header.column.getIsSorted() === "desc" ? (
              <ArrowDownSolidIcon aria-label="sorted descending" boxSize={4} />
            ) : (
              <ArrowUpSolidIcon aria-label="sorted ascending" boxSize={4} />
            )
          ) : null}
        </div>
      </Flex>
    </Th>
  )
}

type TableRowProps = {
  row: Row<Response>
  rowIndex: number
  allRows: Row<Response>[]
  openIndividualResponseDrawer: (id: number) => void
}

const TableRow: React.FC<TableRowProps> = ({
  row,
  rowIndex,
  allRows,
  openIndividualResponseDrawer,
}) => {
  const handleRowClick = () => {
    openIndividualResponseDrawer(row.original.id)
  }

  return (
    <Tr key={row.id} _hover={{ bgColor: "blackAlpha.100" }}>
      {row.getVisibleCells().map((cell, index, allCells) => (
        <Td
          key={cell.id}
          width={`${cell.column.getSize()}px`}
          py="0"
          px="3"
          textAlign="start"
          h="44px"
          borderColor="gray.200"
          borderWidth={1}
          borderLeftWidth={1}
          borderRightWidth={index === allCells.length - 1 ? 0 : 1}
          borderBottomWidth={rowIndex === allRows.length - 1 ? 0 : 1}
          onClick={handleRowClick}
          cursor="pointer"
        >
          {flexRender(cell.column.columnDef.cell, {
            ...cell.getContext(),
          })}
        </Td>
      ))}
    </Tr>
  )
}
