import {
  isTaskComplete,
  isTaskStarted,
  sectionStartTime,
} from "Utilities/response"
import React, { PropsWithChildren, useState } from "react"
import { InnerProps } from "../../props"
import { DocumentPictureInPictureProvider } from "./DocumentPictureInPictureProvider"
import { ExternalStudyActive } from "./ExternalStudyActive"

export const ExternalStudyTask: React.FC<PropsWithChildren<InnerProps>> = ({
  responseSection,
  updateResponseSection,
  usabilityTestSection,
}) => {
  const [clickStartTime, setClickStartTime] = useState<number | null>(null)

  const isStarted = isTaskStarted(responseSection)
  const isComplete = isTaskComplete(responseSection)

  const startTask = () => {
    const startTime = performance.now()

    setClickStartTime(startTime)

    updateResponseSection(usabilityTestSection.id, {
      instructions_duration_ms: startTime - sectionStartTime(responseSection),
    })
  }

  const finishTask = () => {
    const finishTime = performance.now()

    updateResponseSection(usabilityTestSection.id, {
      task_duration_ms: finishTime - (clickStartTime ?? 0),
    })
  }

  if (!isComplete && usabilityTestSection.external_study) {
    return (
      <DocumentPictureInPictureProvider>
        <ExternalStudyActive
          usabilityTestSection={usabilityTestSection}
          isStarted={isStarted}
          handleStart={startTask}
          handleFinish={finishTask}
        />
      </DocumentPictureInPictureProvider>
    )
  }

  // On task completion it will automatically move to the next section
  return null
}
